import React from 'react'

import Layout from "../../Components/reusable/Layout"
import Banner from "../../Components/reusable/Banner"
import frontImg from "../../Images/History/main.jpg"
import leftImg from "../../Images/History/mainleft.jpg"

import rightImg from "../../Images/History/mainright.jpg"
// import dummy from "../../Images/MainCarousel/first.jpg"
import Plant from '../../Components/reusable/Plant'

function History() {
    return (
        <Layout>
         <Banner title="Our History"/>   


    <div className="section">
		<div className="content-wrap">
			<div className="container">
				<div className="row mb-5">
					<div className="col-sm-12 col-md-12">
						<img src={frontImg} alt="" className="img-fluid" width="100%" />	
					</div>
				</div>


				<div className="row">

					<div className="col-sm-12 col-md-4">
						<div className="widget info-detail">
							{/* <h4 className="section-heading text-black no-after mb-3">
								Project <span className="text-primary">Details</span>
							</h4>
							<ul className="list-info">
								<li>
									<div className="info-icon text-black">
										<span className="fas fa-user-tie"></span>
									</div>
									<div className="info-text">RSasmito Studio</div> </li>
								<li>
									<div className="info-icon text-black">
										<span className="fas fa-map-marked-alt"></span>
									</div>
									<div className="info-text">Sidoarjo, East Java, ID</div>
								</li>
								<li>
									<div className="info-icon text-black">
										<span className="fas fa-calendar"></span>
									</div>
									<div className="info-text">March, 2019</div>
								</li>
								<li>
									<div className="info-icon text-black">
										<span className="fas fa-money-bill-wave"></span>
									</div>
									<div className="info-text">$250.000</div>
								</li>
								<li>
									<div className="info-icon text-black">
										<span className="fas fa-globe-americas"></span>
									</div>
									<div className="info-text">www.rudhisasmito.com</div>
								</li>
							</ul>
						 */}
                         <img src={leftImg} alt="" className="img-fluid" width="100%" />
                        </div>

						{/* <div className="widget info-detail">
							<h4 className="section-heading text-black no-after mb-3">
								Download <span className="text-primary">Brochure</span>
							</h4>
							<div className="widget download">
								<ul className="category-nav">
									<li><a href="#" className="btn btn-secondary btn-block btn-sidebar"><span className="fas fa-file-pdf mr-3"></span> Company Profile</a></li>
									<li><a href="#" className="btn btn-secondary btn-block btn-sidebar"><span className="fas fa-file-pdf mr-3"></span> Product Knowledge</a></li>
									<li><a href="#" className="btn btn-secondary btn-block btn-sidebar"><span className="fas fa-file-pdf mr-3"></span> Voluptate Velit</a></li>
								</ul>
							</div>
						</div> */}
						

					</div>
					
					<div className="col-sm-12 col-md-8 pleft">
						<div className="single-page">
							<h4 className="text-primary uk36">
								Kundan Lal and Sons
							</h4>
							<p>
							<ul style={{marginLeft: "0px", paddingLeft: "23px"}}>
								<li>The Company was initially conceived and started by Late Shri Kundan Lal Ji, grandfather of Shri Dig Vijay in November 1929.</li>
								<li>We are manufacturers of Sprockets , gears, shafts for 2 wheelers, 3 and 4 wheelers.</li>
								<li>In addition, agriculture equipment and machinery, industrial and textile machinery are manufactured in our company since 1981.</li>
							</ul> 
							</p>
							<blockquote>Kundan Lal and Sons was established in the year 1965 by Late Shri Dig Vijay at the age of 22 years as proprietorship firm.</blockquote>
							
						
							{/* <div className="accordion rs-accordion" id="accordionExample">
							  
							   <div className="card">
							      <div className="card-header" id="headingOne">
							         <h3 className="title">
							            <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
							            Exercitation ullamco laboris nisi
							            </button>
							         </h3>
							      </div>
							      <div id="collapseOne" className="collapse" aria-labelledby="headingOne" data-parent="#accordionExample">
							         <div className="card-body">
							            Create and publilsh dynamic websites for desktop, tablet, and mobile devices that meet the latest web standards- without writing code. Design freely using familiar tools and hundreds of web fonts. easily add interactivity, including slide shows, forms, and more.
							         </div>
							      </div>
							   </div>
							   
							   <div className="card">
							      <div className="card-header" id="headingTwo">
							         <h3 className="title">
							            <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
							            Excepteur sintoccaecat cupidatat non proident
							            </button>
							         </h3>
							      </div>
							      <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
							         <div className="card-body">
							            Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.
							         </div>
							      </div>
							   </div>
							   
							   <div className="card">
							      <div className="card-header" id="headingThree">
							         <h3 className="title">
							            <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
							            Officia deserunt mollit anim
							            </button>
							         </h3>
							      </div>
							      <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
							         <div className="card-body">
							            <p>Unzip the file, locate Html file and double click the file and you will directly to adobe Html. Next step you can modifications our template, you can customize color, text, font, content, logo and image with your need using familiar tools on adobe Html without writing any code.</p>
							            <p>You can't re-distribute the Item as stock, in a tool or template, or with source files. You can't re-distribute or make available the Item as-is or with superficial modifications. These things are not allowed even if the re-distribution is for Free.</p>
							         </div>
							      </div>
							   </div>
							  
							   <div className="card">
							      <div className="card-header" id="headingFour">
							         <h3 className="title">
							            <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
							            Utenim ad minim veniam
							            </button>
							         </h3>
							      </div>
							      <div id="collapseFour" className="collapse" aria-labelledby="headingFour" data-parent="#accordionExample">
							         <div className="card-body">
							            <p>Unzip the file, locate Html file and double click the file and you will directly to adobe Html. Next step you can modifications our template, you can customize color, text, font, content, logo and image with your need using familiar tools on adobe Html without writing any code.</p>
							            <p>You can't re-distribute the Item as stock, in a tool or template, or with source files. You can't re-distribute or make available the Item as-is or with superficial modifications. These things are not allowed even if the re-distribution is for Free.</p>
							         </div>
							      </div>
							   </div>
							  
							   <div className="card">
							      <div className="card-header" id="headingFive">
							         <h3 className="title">
							            <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
							            Dolore magna aliqua
							            </button>
							         </h3>
							      </div>
							      <div id="collapseFive" className="collapse" aria-labelledby="headingFive" data-parent="#accordionExample">
							         <div className="card-body">
							            <p>Unzip the file, locate Html file and double click the file and you will directly to adobe Html. Next step you can modifications our template, you can customize color, text, font, content, logo and image with your need using familiar tools on adobe Html without writing any code.</p>
							            <p>You can't re-distribute the Item as stock, in a tool or template, or with source files. You can't re-distribute or make available the Item as-is or with superficial modifications. These things are not allowed even if the re-distribution is for Free.</p>
							         </div>
							      </div>
							   </div>
							</div>
							 

							<p className="mt-2"><em>* Veniam quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat consectetur adipiscing elit tellus luctus nec ullamcorper mattis.</em></p>
*/}
						 </div>
					</div>
                    
				</div>

                <div className="row py-5">
                    <div className="col-sm-12 col-md-12 pleft">
                    <p style={{textAlign:"center"}}>The Company was initially conceived and started by Late Shri Kundan Lal Ji , grandfather of Shri Dig Vijay in November 1929 and founder of <a href="https://en.wikipedia.org/wiki/Kundan_Vidya_Mandir,_Ludhiana" style={{color:"#CD2D1D"}}>Kundan Vidya Mandir Senior Secondary</a>, first school for girls in Ludhiana.
We are manufacturers of Sprockets for two wheelers, Automotive sprockets, Agriculture sector and Industrial machinery gears, spline shafts, Fork cone (Ball Racer) sets. 
We have in-house room to develop Tools & Dies for Press jobs. We develop in-house all critical tools and gauges for our production process and have an R & D Deptt. to develop new technologies. We incorporate to give better products for our customers all over the world.</p>
                    </div>
                </div>

                <div className="row py-5">
                    <div className="col-sm-12 col-md-8 ">
						<div className="single-page">
							<h4 className="text-primary uk36">
								Our Vision
							</h4>
                            <p>To create a learning environment where employees and vendors work 
with positive attitude towards the economical and technological growth 
of the Company leading to delightful customers.
</p>
                        </div>
                    </div>
					<div className="col-sm-12 col-md-4">
						<div className="widget info-detail">
                            <img src={rightImg} alt="" className="img-fluid" width="100%" />
                        </div>
                    </div>
                </div>


                <div className="row pt-5">
                    <div className="col-sm-12 col-md-12 ">
						<div className="single-page">
							<h4 className="text-primary  uk36">
                            Quality Objectives
							</h4>
                            <p >
                            We at KUNDAN LAL AND SONS hereby commit ourselves to achieve the Quality Policy by:
							<ol type="1" style={{marginLeft: "0px", paddingLeft: "23px", marginTop:"20px"}}>
								<li>On time delivery by 95%</li>
								<li>Process Automation towards better work environment.</li>
								<li>New product introduction in next two years.</li>
							</ol>

                            </p>
                        </div>
                    </div>
                </div>






            </div>
		</div>
	</div> 

	<Plant />


        </Layout>
    )
}

export default History
